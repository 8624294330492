//# добавити [data-search="#search"] для button
/* 
#<button data-search="#search" class="action-header__submit _icon-search"></button>
#<form id="search" action="#" class="action-header__form">
#  <button class="action-header__submit _icon-search" type="submit"></button>
#  <input class="action-header__inp" type="text" placeholder="Знайти..." required />
#</form>
 */

const searchBtn = document.querySelector('[data-search]')
const search = document.querySelector(searchBtn?.dataset.search)

searchBtn.addEventListener('click', seacrhToggle)
searchBtn.addEventListener('keydown', handleKeydown)

document.addEventListener('click', e => {
  if (search?.classList.contains('_active') && !e.target.closest('[data-search]') && !e.target.closest(searchBtn?.dataset.search)) {
    seacrhToggle()
  }
})

function seacrhToggle() {
  search.classList.toggle('_active')
}

function handleKeydown(e) {
  console.log(e.key)
  if (e.key === 'Escape' && search?.classList.contains('_active')) {
    search.classList.remove('_active')
  }
}
