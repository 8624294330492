//* Example HTML
//* <div data-da="991.98, .menu__body">
//* <div data-da="$1, $2, $3">
//* $1 - media
//* $2 - selector where to move
//* $3 - position[number | 'first','last','prev','next'] default: 'last'

//# findNumPrev
function findNumPrev(el, child) {
  return child === el
}

//# sort
function sort(a, b) {
  if (a.num < b.num) return -1
  else if (a.num > b.num) return 1
  else return 0
}

let data = []

//# main function

dynamicAdaptive()
function dynamicAdaptive() {
  const elements = Array.from(document.querySelectorAll('[data-da]'))

  data = elements.map(dataFromElements)
  data = data.sort(sort)
  data.forEach(initMedia)
}

//# dataFromElements
function dataFromElements(el) {
  const dataAttr = el.dataset.da.split(',')

  const media = dataAttr[0].trim()
  const num = dataAttr[2] ? (+dataAttr[2] ? +dataAttr[2].trim() - 1 : dataAttr[2].trim()) : 'last'
  const parent = dataAttr[3] ? dataAttr[3].trim() : false
  const to = parent ? el.closest(parent).querySelector(dataAttr[1].trim()) : document.querySelector(dataAttr[1].trim())

  const toPrev = el.parentElement
  let numPrev = Array.from(el.parentElement.children).findIndex(findNumPrev.bind(null, el))

  if (num === 'next') numPrev++
  if (num === 'prev') numPrev--

  const previous = { to: toPrev, num: numPrev }

  return { element: el, media, to, num, parent, previous }
}

//# initMedia
function initMedia(d) {
  const media = window.matchMedia(`(max-width: ${d.media}px)`)

  media.addListener(listener.bind(null, d))
  listener(d, media, true)
}

//# listener
function listener(d, e, start = false) {
  const element = d.element

  if (e.matches) {
    const { to, position } = auditChildren(d.to, d.num)
    insertElement(element, to, position)
  } else if (!start) {
    const { to, position } = auditChildren(d.previous.to, d.previous.num)
    insertElement(element, to, position)
  }
}

//# auditChildren
function auditChildren(parent, num) {
  let to = parent
  let position

  if (num === 0 || num === 'first') {
    position = 'afterbegin'
  } else if (num >= parent?.children?.length || num === 'last') {
    position = 'beforeend'
  } else if (num === 'next') {
    position = 'afterend'
  } else if (num === 'prev') {
    position = 'beforebegin'
  } else {
    to = parent?.children[num - 1]
    position = 'afterend'
  }

  return { to, position }
}

//# insertElement
function insertElement(element, to, position) {
  element.remove()

  to.insertAdjacentElement(position, element)
}
