import Swiper, { Keyboard, Pagination, EffectFade, Autoplay } from 'swiper'

// # main function
sliders()
function sliders() {
  addClassSwiper()

  if (document.querySelector('.slider-main')) {
    new Swiper('.slider-main', {
      direction: 'horizontal',
      effect: 'fade',
      loop: false,
      speed: 1000,
      spaceBetween: 0,
      slidesPerView: 'auto',
      watchOverflow: true,
      simulateTouch: false,

      modules: [Pagination, Keyboard, EffectFade, Autoplay],

      fadeEffect: {
        crossFade: true,
      },

      pagination: {
        el: '.slider-main__pagination',
        //# Рядок із типом розбивки на сторінки. Можуть бути 'bullets', 'fraction', 'progressbar' або 'custom'
        type: 'bullets',
        //# можна натиснути, коли <type>: 'bullets'
        clickable: true,
        //# динамічні кулі, коли <type>: 'bullets'
        dynamicBullets: false,
        //# Кількість основних маркерів, видимих, коли ввімкнено <dynamicBullets>.
        // dynamicMainBullets: 1,
      },

      keyboard: {
        enabled: true,
      },

      autoplay: {
        delay: 3000,
        //# Установіть значення «false» і автовідтворення запускатиметься щоразу після взаємодії
        disableOnInteraction: false,
        //# автовідтворення буде призупинено під час наведення миші на контейнер Swiper
        //# Якщо <disableOnInteraction> також увімкнено, автоматичне відтворення буде зупинено
        pauseOnMouseEnter: false,
        reverseDirection: false,
        stopOnLastSlide: false,
      },
    })
  }
}

//# addClassSwiper
function addClassSwiper() {
  const $sliders = document.querySelectorAll('[class*="__swiper"]')

  $sliders.forEach(slider => {
    slider = slider.parentElement
    const wrapper = slider.children[0]
    const slides = Array.from(wrapper.children)

    slider.classList.add('swiper')
    wrapper.classList.add('swiper-wrapper')
    slides.forEach(addClassSlide)
  })
}

//# addClassSlide
function addClassSlide(slide) {
  slide.classList.add('swiper-slide')
}
