//# data-scroll-to="selector"

//# initScrollTo
initScrollTo()
function initScrollTo() {
  const links = document.querySelectorAll('[data-scroll-to]')
  if (links.length > 0) {
    document.addEventListener('click', click)
  }
}

//# click
function click(e) {
  if (e.target.closest('[data-scroll-to]')) {
    const $el = e.target.closest('[data-scroll-to]')
    const dataArray = $el.dataset.scrollTo.split(', ')
    const [selector, type] = dataArray
    const headerHeight = window.headerHeight
    // const headerHeight = document.querySelector('.top-header').getBoundingClientRect().height
    const documentScroll = document.documentElement.scrollTop

    let top

    if (type === 'next') {
      top = document.querySelector(selector).getBoundingClientRect().height - headerHeight
    } else {
      top = document.querySelector(selector).getBoundingClientRect().top + documentScroll - headerHeight - 40
    }

    window.scrollTo({ top: top, behavior: 'smooth' })

    e.preventDefault()
  }
}
