import { hideScrolling, showScrolling } from './helpers/hideScrolling.js'

document.addEventListener('click', click)
document.addEventListener('keydown', keydown)

function keydown(e) {
  if (e.keyCode === 27) {
    const $control = document.querySelector('[data-menu-burger]')

    if ($control?.getAttribute('aria-expanded') === 'true') {
      close($control)
    }
  }
}

function click(e) {
  if (e.target.closest('[data-menu-burger]')) {
    const $control = e.target.closest('[data-menu-burger]')
    toggle($control)
  }
  if (e.target.closest('[data-scroll-to]')) {
    const $control = document.querySelector('[data-menu-burger]')
    close($control)
  }
}

function toggle($control) {
  if ($control.getAttribute('aria-expanded') === 'false') {
    open($control)
    return
  }
  close($control)
}

function open($control) {
  const $menu = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  $control.setAttribute('aria-expanded', 'true')
  $menu.setAttribute('aria-hidden', 'false')

  hideScrolling()
}

function close($control) {
  const $menu = document.querySelector(`#${$control.getAttribute('aria-controls')}`)

  $control.setAttribute('aria-expanded', 'false')
  $menu.setAttribute('aria-hidden', 'true')

  showScrolling()
}
