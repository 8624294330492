import { hideScrolling, showScrolling } from './helpers/hideScrolling.js'
import 'wicg-inert'

let $opener

document.addEventListener('click', click)
document.addEventListener('keydown', keydown)

function keydown(e) {
  if (e.keyCode === 27) {
    const $popupActive = document.querySelector('.popup_active')
    if ($popupActive) close($popupActive)
  }
}

function click(e) {
  const $el = e.target

  if ($el.closest('[data-popup-control]')) {
    open($el.closest('[data-popup-control]'))
    e.preventDefault()
    return
  }
  if ($el.closest('[data-popup-close]') || $el.classList.contains('popup__body')) {
    close($el.closest('.popup'))
    return
  }
}

function open($control) {
  const $popup = document.querySelector($control.dataset.popupControl)

  if ($popup.isBlocked) return

  $opener = $control
  $popup.isBlocked = true
  $popup.style.display = 'block'

  setTimeout(() => {
    setInertBehindModal(true)

    $popup.classList.add('popup_active')
    $popup.isBlocked = false

    hideScrolling()
    setFocus($popup)
  }, 10)
}

function close($popup) {
  if ($popup.isBlocked) return

  $popup.isBlocked = true
  $popup.classList.remove('popup_active')

  setTimeout(() => {
    setInertBehindModal(false)

    $popup.style.removeProperty('display')
    $popup.isBlocked = false

    showScrolling()
    $opener.focus()
  }, 330)
}

function setFocus($popup) {
  $popup.querySelectorAll('button, a[href], input, textarea, select')[0].focus()
}

function setInertBehindModal(inert) {
  const $wrapper = document.querySelector('.wrapper')
  const videos = document.querySelectorAll('video')

  $wrapper.inert = inert
  $wrapper.setAttribute('aria-hidden', inert)

  videos.forEach($video => {
    $video.inert = inert
    $video.setAttribute('aria-hidden', inert)
    $video.setAttribute('tabindex', inert ? -1 : 0)
  })
}
