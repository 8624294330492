document.addEventListener('click', click)
document.addEventListener('keydown', keydown)

const getOpenSelect = () => document.querySelector('[data-select][aria-expanded="true"]')
const deselectOption = $option => $option.setAttribute('aria-selected', false)
const isFocused = $option => $option.hasAttribute('tabindex')

function keydown(e) {
  if (e.key === 'Escape' && getOpenSelect()) {
    close(getOpenSelect())
    return
  }

  if (e.target.closest('[data-selected]') && (e.key === 'Enter' || e.key === ' ')) {
    toggle(e.target.closest('[data-select]'))
    e.preventDefault()
    return
  }

  if (e.target.closest('[role="listbox"]') && e.target.closest('[data-select]')) {
    const $listbox = e.target.closest('[role="listbox"]')

    if (e.key === 'ArrowDown') {
      focusNextOption($listbox)
      e.preventDefault()
      return
    }
    if (e.key === 'ArrowUp') {
      focusPreviousOption($listbox)
      e.preventDefault()
      return
    }
  }
  if (e.target.closest('[role="option"]') && (e.key === 'Enter' || e.key === ' ')) {
    selectOption(e.target.closest('[role="option"]'))
    e.preventDefault()
  }
}

function click(e) {
  const $el = e.target

  if ($el.closest('[data-selected]')) {
    if (getOpenSelect() && getOpenSelect() !== $el.closest('[data-select]')) close(getOpenSelect())
    toggle($el.closest('[data-select]'))
    return
  }
  if (!$el.closest('[data-select][aria-expanded="true"]') && getOpenSelect()) {
    close(getOpenSelect())
    return
  }
  if ($el.closest('[role="option"]')) {
    selectOption($el.closest('[role="option"]'))
  }
}

function toggle($select) {
  if ($select.getAttribute('aria-expanded') === 'false') {
    open($select)
    return
  }
  close($select)
}

function open($select) {
  const $listbox = $select.querySelector('[role="listbox"]')

  $select.setAttribute('aria-expanded', true)
  $listbox.setAttribute('aria-hidden', false)

  setTimeout(() => {
    focusEl($listbox)
  }, 100)
}

function close($select) {
  const $listbox = $select.querySelector('[role="listbox"]')
  const $selected = $select.querySelector('[data-selected]')
  const $focusedOption = $listbox.querySelector('[tabindex]')

  $select.setAttribute('aria-expanded', false)
  $listbox.setAttribute('aria-hidden', true)

  if ($focusedOption) defocusEl($focusedOption)

  focusEl($selected)
}

function selectOption($currentOption) {
  const $select = $currentOption.closest('[data-select]')
  const options = $select.querySelectorAll('[role="listbox"] [role="option"]')
  const $selected = $select.querySelector('[data-selected]  .text')

  options.forEach(deselectOption)
  $currentOption.setAttribute('aria-selected', true)
  $selected.textContent = $currentOption.textContent

  close($select)
}

function focusNextOption($list) {
  const options = Array.from($list.children)
  const length = options.length
  let indexFocused = options.findIndex(isFocused)

  indexFocused++

  if (indexFocused === length) indexFocused = 0

  options.forEach(defocusEl)
  focusEl(options[indexFocused])
}

function focusPreviousOption($list) {
  const options = Array.from($list.children)
  const length = options.length
  let indexFocused = options.findIndex(isFocused)

  indexFocused--

  if (indexFocused < 0) indexFocused = length - 1

  options.forEach(defocusEl)
  focusEl(options[indexFocused])
}

function focusEl($el) {
  $el.setAttribute('tabindex', '0')
  $el.focus()
}

function defocusEl($el) {
  $el.removeAttribute('tabindex')
}
