//# data-imask="^[1-6]\d{0,5}$ type: regexp"
//# data-imask="+{38} (000) 000 00 00"

import IMask from 'imask'

const imaskElements = document.querySelectorAll('[data-imask]')

imaskElements.forEach(el => {
  let [mask, type] = el.dataset.imask.split(' type: ')

  if (type === 'regexp') {
    mask = new RegExp(mask)
  }

  IMask(el, {
    mask,
  })
})
