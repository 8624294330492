//# вказати header ._fixed
//# при скролі додає в header ._scrolled
//# при скролі в гору додає в header ._scrolled-top
//# створює змінну в css - [--headerHeight]
//# добавити - .wrapper { padding-top: var(--headerHeight); }

fixedHeader()
function fixedHeader() {
  const header = document.querySelector('.header')
  const body = document.querySelector('body')

  if (!header) return

  window.addEventListener('scroll', scroll)

  let scrolled = false
  let height

  setTimeout(() => {
    scroll()
  }, 100)

  function scroll() {
    const scrollTop = document.documentElement.scrollTop
    height = header.offsetHeight

    if (scrollTop > 1 && !scrolled) {
      if (header.classList.contains('_fixed')) {
        header.classList.add('_scrolled')
      }

      height = header.offsetHeight
      scrolled = !scrolled
    } else if (scrollTop <= 1 && scrolled) {
      if (header.classList.contains('_fixed')) {
        header.classList.remove('_scrolled')
      }

      height = header.offsetHeight
      scrolled = !scrolled
    }
    body.style.setProperty('--header-height', `${height ? height / 16 : 0}rem`)

    window.headerHeight = header.offsetHeight
  }
}

// function fixedHeader() {
//   const header = document.querySelector('.header')
//   const body = document.querySelector('body')

//   let scrolledPrevValue = document.documentElement.scrollTop

//   if (!header || !header.classList.contains('_fixed')) return

//   setTimeout(() => {
//     window.addEventListener('scroll', debounce(myScroll, 100))
//   }, 100)

//   function myScroll() {
//     const scrollTop = document.documentElement.scrollTop
//     const isScrolledTop = header.classList.contains('_scrolled-top')

//     if (!isScrolledTop) {
//       if (scrollTop >= scrolledPrevValue) {
//         scrolledPrevValue = scrollTop
//       } else if (scrollTop < scrolledPrevValue - 150) {
//         header.classList.add('_scrolled-top')
//         scrolledPrevValue = scrollTop
//       }
//     } else {
//       if (scrollTop <= scrolledPrevValue) {
//         scrolledPrevValue = scrollTop
//       }
//       if (scrollTop > scrolledPrevValue + 50) {
//         header.classList.remove('_scrolled-top')
//         scrolledPrevValue = scrollTop
//       }
//     }

//     const height = header.offsetHeight
//     const isScrolled = scrollTop > 1
//     let hasClassScrolled = header.classList.contains('_scrolled')

//     if (isScrolled && !hasClassScrolled) {
//       header.classList.add('_scrolled')
//     } else if (!isScrolled && hasClassScrolled) {
//       header.classList.remove('_scrolled')
//     }

//     body.style.setProperty('--header-height', `${height / 16}rem`)
//     window.headerHeight = height
//   }
// }

// function debounce(func, delay) {
//   let timer

//   return function () {
//     clearTimeout(timer)

//     timer = setTimeout(() => {
//       func.apply(this, arguments)
//     }, delay)
//   }
// }
