export function hideScrolling() {
  const $body = document.body
  const $wrapper = document.querySelector('.wrapper')
  const scrollWidth = window.innerWidth - $wrapper.offsetWidth

  $body.style.setProperty('--scroll-width', `${scrollWidth}px`)
  $body.classList.add('_hide-scrolling')
}

export function showScrolling() {
  document.body.classList.remove('_hide-scrolling')
}
